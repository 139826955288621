/* ========================================================================
     Component: offsidebar
 ========================================================================== */

//
// A sidebar in offcanvas mode
// Open status toggled via JS
// -----------------------------------------

$offsidebar-bg: #fff;
$offsidebar-border-color: gray(darken($offsidebar-bg, 20%));
$offsidebar-color: $text-color;
$offsidebar-wd: $aside-wd + 15px; // match the width with the main aside component

.offsidebar {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: $offsidebar-wd;
    //margin-top: ($navbar-height + 2) * 2;
    margin-top: $navbar-hg;
    border-left: 1px solid $offsidebar-border-color;
    background-color: $offsidebar-bg;
    color: $offsidebar-color;
    z-index: $z-index-main-section + 5;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    @include transition(all 0.3s ease);

    > nav {
        min-height: 100%;
        overflow: hidden;
        -webkit-transform: translateZ(0px);
    }

    .progress {
        border: 0;
    }

    .nav > li > a {
        &:hover,
        &:focus {
            background-color: rgba(0, 0, 0, 0.025);
        }
    }

    .tab-content {
        padding: 0;
        border: 0;
    }
    .nav-tabs.nav-justified > li {
        > a,
        > a:hover,
        > a:focus {
            background-color: transparent;
            border: 0;
            border-right: 1px solid rgba(0, 0, 0, 0.05);
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);
            border-radius: 0;
            color: $text-muted;
        }
        &.active > a {
            //border-bottom-color: $brand-primary;
            color: $brand-secondary;
        }
    }

    @media only screen and (min-width: $mq-tablet) {
        margin-top: $navbar-height;
    }
}

// Global open status (attach to body)
.offsidebar-open {
    -webkit-overflow-scrolling: touch;
    .offsidebar {
        overflow-y: auto;
    }
    @media only screen and (min-width: $mq-tablet) {
        overflow-y: auto;
    }
}

// No tranformation devices
.offsidebar {
    right: -$offsidebar-wd;
}
.offsidebar-open {
    .offsidebar {
        right: 0;
    }
}
