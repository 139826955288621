/* ========================================================================
     Component: svg-icon
 ========================================================================== */

svg-icon {
    display: inline-block;
    width: 14px;
    height: 14px;
    color: currentColor;

    svg {
        display: inline-block;
        height: 100%;
        width: 100%;
        color: currentColor;
        fill: currentColor;
        stroke: currentColor;
    }
}

svg.icon-lg {
    width: 17px;
    height: 17px;
    vertical-align: -15%;
}

.ph-light,
.ph-thin,
.ph-fill {
    font-size: 19px;

    &.icon-xs {
        font-size: 13px;
    }
    &.icon-sm {
        font-size: 15px;
    }
    &.icon-md {
        font-size: 17px;
    }
    &.icon-lg {
        font-size: 21px;
    }
    &.icon-xl {
        font-size: 23px;
    }
    &.icon-xxl {
        font-size: 25px;
    }
}
