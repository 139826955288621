/* ========================================================================
     Component: form-elements
 ========================================================================== */

/**
 * Custom form elements
 *    - Checkbox
 *    - Radios
 *    - Switch
 *    - Rounded inputs
 */

//
// CHECKBOX AND RADIOS
//

$ui-fg-color: $brand-secondary;
$ui-bg-color: #ddd;

@mixin switch-variant($wd, $hg) {
    & span {
        width: $wd;
        height: $hg;
    }

    & span:after {
        height: $hg - 2;
        width: $hg - 2;
    }
}

.c-checkbox,
.c-radio {
    margin-right: 4px;
    * {
        cursor: pointer;
    }
    input {
        opacity: 0;
        position: absolute;
        margin-left: 0 !important;
    }

    // the element that replaces the input
    span {
        position: relative;
        display: inline-block;
        vertical-align: top;
        margin-left: -20px;
        width: 20px;
        height: 20px;
        border-radius: 2px;
        border: 1px solid #ccc;
        margin-right: 5px;
        text-align: center;
        &:before {
            margin-left: 1px; // fa align trick
        }
    }

    &:hover span {
        border-color: $ui-fg-color;
    }

    .form-inline & span {
        margin-left: 0;
    }

    &.c-checkbox-rounded,
    &.c-radio-rounded {
        span {
            border-radius: 500px;
        }
    }
}

/* override for radio */
.c-radio span {
    border-radius: 500px;
}

/* the icon */
.c-checkbox,
.c-radio {
    span:before {
        //content: "\2713"; /* comment content to use custom icon classes */
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        text-align: center !important;
        font-size: 12px;
        line-height: 18px;
        vertical-align: middle;
    }
}

/* Checked state */
.c-checkbox,
.c-radio {
    input[type='checkbox']:checked + span:before,
    input[type='radio']:checked + span:before {
        color: #fff;
        opacity: 1;
        transition: color 0.3s ease-out;
    }

    input[type='checkbox']:checked + span,
    input[type='radio']:checked + span {
        border-color: $ui-fg-color;
        background-color: $ui-fg-color;
    }

    /* override for radio */
    input[type='radio']:checked + span {
        background-color: #fff;
        &:before {
            color: $ui-fg-color;
        }
    }

    /* Disable state */

    input[type='checkbox']:disabled + span,
    input[type='radio']:disabled + span {
        border-color: $ui-bg-color !important;
        background-color: $ui-bg-color !important;
    }
    /* override for radio */
    input[type='radio']:disabled + span {
        background-color: #fff !important;
        &:before {
            color: $ui-bg-color;
        }
    }
}

.c-radio.c-radio-nofont {
    span {
        &:before {
            $sz: 10px;
            content: '';
            width: $sz;
            height: $sz;
            top: 50%;
            left: 50%;
            margin-top: -($sz/2);
            margin-left: -($sz/2);
            border-radius: 500px;
        }
    }

    input[type='radio']:checked + span:before {
        color: #fff;
        opacity: 1;
        transition: color 0.3s ease-out;
    }

    input[type='radio']:checked + span {
        border-color: $ui-fg-color;
        background-color: $ui-fg-color;
    }

    /* override for radio */
    input[type='radio']:checked + span {
        background-color: #fff;
        &:before {
            background-color: $ui-fg-color;
        }
    }

    /* Disable state */

    input[type='radio']:disabled + span {
        border-color: $ui-bg-color !important;
        background-color: $ui-bg-color !important;
    }
    /* override for radio */
    input[type='radio']:disabled + span {
        background-color: #fff !important;
        &:before {
            background-color: $ui-bg-color;
        }
    }
}

.form-control {
    padding: 12px;
    height: auto;
    border-color: $border-color;
    &:focus,
    &:active {
        outline: 1px solid $brand-secondary;
        border-color: $brand-secondary;
    }
}

.form-group {
    display: flex;
    align-items: center;
    &.vertical {
        display: block;
    }
}

select.form-control {
    height: 45px;
    // webkit, mozilla specific
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    -moz-appearance: none;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPScxLjEnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZycgeG1sbnM6eGxpbms9J2h0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsnIHdpZHRoPScyNCcgaGVpZ2h0PScyNCcgdmlld0JveD0nMCAwIDI0IDI0Jz48cGF0aCBmaWxsPSclMjM0NDQnIGQ9J003LjQwNiA3LjgyOGw0LjU5NCA0LjU5NCA0LjU5NC00LjU5NCAxLjQwNiAxLjQwNi02IDYtNi02eic+PC9wYXRoPjwvc3ZnPg==');
    background-size: 24px 24px;
    background-position: 98% 50%;
    background-repeat: no-repeat;
}

.form-group {
    > label.form-label,
    > label.col-form-label {
        padding-top: 12px;
        padding-bottom: 12px;
        border: 1px solid transparent;
        border-left: none;
        border-right: none;
        align-self: flex-start;

        &.timepicker-label {
            margin-top: 33px;
        }
    }
}
@media only screen and (min-width: $mq-tablet) {
    label.col-form-label {
        text-align: right;
        margin: 0;
        float: none;
    }
}

.filter-wrapper {
    padding: 0;
    label {
        display: block;
    }
    .form-inline .form-group {
        vertical-align: top;
        margin-right: 10px;
    }
}

h3.form-label {
    font-size: 18px;
    color: darken($text-color, 10%);
}

span.label {
    border-radius: 10px;
    min-width: 36px;
    display: inline-block;
    min-height: 10px;
}

//
// Form Rounded
//

.form-control-rounded {
    border-radius: 100px;
}

//
// Form SWITCH
// Supports radio and checkbox
//

$switch-wd: 40px;
$switch-hg: $switch-wd/2;

$switch-lg-wd: 50px;
$switch-lg-hg: $switch-lg-wd/2;

$switch-sm-wd: 30px;
$switch-sm-hg: $switch-sm-wd/2;

.switch {
    .form-control {
        padding-top: 7px;
        margin-bottom: 0;
    }
}

.switch * {
    cursor: pointer;
}

.switch input {
    opacity: 0;
    position: absolute;
    z-index: -1;
}

.switch {
    & span {
        position: relative;
        display: inline-block;
        width: $switch-wd;
        height: $switch-hg;
        background-color: #fff;
        border: 1px solid $ui-bg-color;
        border-radius: 100px;
        transition: all 0.5s;
        box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.1) inset;
        vertical-align: middle;
    }

    & span:after {
        content: '';
        position: absolute;
        background-color: #fff;
        top: 0;
        left: 0;
        height: $switch-hg - 2;
        width: $switch-hg - 2;
        border: 1px solid #ddd;
        border-radius: 400px;
        box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
        -webkit-transition: all 0.2s;
    }

    &.switch-lg {
        @include switch-variant($switch-lg-wd, $switch-lg-hg);
    }
    &.switch-sm {
        @include switch-variant($switch-sm-wd, $switch-sm-hg);
    }
}

.switch input:checked + span {
    background-color: $ui-fg-color;
    border-color: $ui-fg-color;
    transition: all 0.5s;
}

.switch input:checked + span:after {
    left: 50%;
    transition: all 0.2s;
}

.switch input:checked:disabled + span {
    background-color: $ui-fg-color;
    cursor: not-allowed;
}

.switch input:disabled + span {
    background-color: #f1f1f1;
    cursor: not-allowed;
}

// form feedback variants

.form-control-feedback {
    // top: 10px;
    left: $padding-base-horizontal;
    right: auto;

    .form-control + &,
    .form-control + .parsley-errors-list + & {
        right: 0;
        left: auto;
        // top: 10px;
    }

    .input-sm + &,
    .input-sm + .parsley-errors-list + & {
        top: 0;
    }

    label + .form-control + &,
    label + .form-control + .parsley-errors-list + & {
        // top: 36px;
    }
}

.input-md {
    width: 260px;
}

.note-editor {
    background-image: -webkit-linear-gradient(#eee 0.1em, transparent 0.1em);
    background-image: linear-gradient(#eee 0.1em, transparent 0.1em);

    background-size: 100% 20px;
    background-color: #fff;

    line-height: 20px;
    margin-top: 5px;
    padding: 0;
    padding-bottom: 1px;
    border: none;
    $margin-color: $brand-warning;
    &.note-editor-margin {
        background-image: -webkit-linear-gradient(
                0deg,
                transparent 49px,
                lighten($margin-color, 30%) 49px,
                lighten($margin-color, 30%) 51px,
                transparent 51px
            ),
            -webkit-linear-gradient(#eee 0.1em, transparent 0.1em);
        background-image: linear-gradient(
                90deg,
                transparent 49px,
                lighten($margin-color, 30%) 49px,
                lighten($margin-color, 30%) 51px,
                transparent 51px
            ),
            linear-gradient(#eee 0.1em, transparent 0.1em);
        padding-left: 55px;
    }
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider,
.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
    background: $brand-info;
}
