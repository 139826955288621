/* ========================================================================
     Component: dropdown-extras
 ========================================================================== */

//
// Dropdopwn extras
//
// ------------------------------

// place components with more space
.dropdown-lg > .dropdown-menu {
    min-width: 200px;
}

// Allows to mix list group inside dropdowns
.dropdown-list {
    > .dropdown-menu {
        padding: 0;
        min-width: 220px;
    }

    .list-group {
        margin: 0;
    }

    .list-group-item {
        border-radius: 0;
        border-left: 0;
        border-right: 0;
        &:first-child {
            border-top: 0;
        }
        &:last-child {
            border-bottom: 0;
        }
    }
}

// Labels inside dropdowns
.dropdown {
    > a {
        position: relative;
        > .label {
            position: absolute;
            top: 0;
            right: 0;
            padding: 2px 5px;
            @media only screen and (min-width: $mq-tablet) {
                top: 10px;
            }
        }
    }
}

// Dropdown header
.dropdown-menu-header {
    padding: 10px 15px;
    background-color: #fafafa;
    border-bottom: 1px solid $dropdown-border;
}

//PrimeNg dropdown

.p-dropdown {
    width: 100%;
    font-size: $primeng-font-size;
}
.p-inputtext {
    font-size: $primeng-font-size;
    padding: 12px;
    line-height: var(--bs-body-line-height);
    color: $input-color;
}
.p-dropdown-item:hover {
    background-color: #f8f9fa;
}

.p-dropdown-filter {
    border: none;
    padding: 0.5rem 1rem;
}

.p-dropdown-trigger {
    border: none;
    background-color: transparent;
    padding: 0.5rem 1rem;
}
.p-dropdown-panel .p-dropdown-items {
    padding: 0;

    .p-dropdown-item {
        font-size: $primeng-font-size;
        color: $input-color;
        padding: 6px 12px;
        transition: background-color 0.15s ease-in-out;
        &.p-highlight {
            background: $brand-info;
        }
    }
}

//date picker
.p-calendar-w-btn {
    width: 100%;

    .p-datepicker-trigger {
        background: #fff;
        border-color: rgba(0, 0, 0, 0.12);
        color: $text-color;
        border-left: none;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;

        &:hover,
        &:focus,
        &:active,
        &:active:focus,
        &:enabled:focus {
            background: #fff;
            border-color: rgba(0, 0, 0, 0.12);
            box-shadow: none;
            color: $brand-info;
        }
    }
    .p-inputtext {
        border-color: rgba(0, 0, 0, 0.12);
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        border-right: none;
        &:enabled:focus {
            border-color: rgba(0, 0, 0, 0.12);
            box-shadow: none;
        }
    }
}

.p-datepicker {
    &:not(.p-datepicker-inline) {
        min-width: 275px !important;
        .p-datepicker-header {
            font-size: 14px !important;
            font-weight: 700;
            background: #ffffff;
            .p-datepicker-title {
                line-height: 0;
            }
        }
        table {
            font-size: 12px !important;
            text-align: center;

            td {
                padding: 1px 3px !important;
            }
        }
        .p-datepicker-calendar {
            .p-element {
                font-size: 12px !important;
                width: 33px;
                height: 33px;
                border-radius: 50%;
            }
        }
        .p-datepicker-buttonbar {
            padding: 5px 0;
            .p-button.p-button-text {
                color: $brand-info;
                padding: 3px 14px;
            }
        }
        table td > span.p-highlight {
            background-color: $brand-info;
        }
        .p-disabled,
        .p-component:disabled {
            opacity: 0.2;
        }
    }
}
