/* ========================================================================
     Component: progress-extra
 ========================================================================== */

// Different size of Progress bars

$progress-height-sm: 15px;
$progress-height-xs: 8px;

.progress-sm.progress,
.progress-sm .progress {
    height: $progress-height-sm;
}

.progress-xs.progress,
.progress-xs .progress {
    height: $progress-height-xs;
}

.m0 .progress {
    margin: 0;
}
// Progress color variants

.progress-bar-purple {
    background-color: $brand-purple;
}
.progress-bar-inverse {
    background-color: $brand-inverse;
}
.progress-bar-green {
    background-color: $brand-green;
}
.progress-bar-pink {
    background-color: $brand-pink;
}
.progress-bar-yellow {
    background-color: $brand-yellow;
}
.progress-score {
    margin: 0;
    height: 23px !important;
    border: none;
    .progress-bar {
        margin-right: 3px;
        border-radius: 5px;
        color: $brand-text;
        overflow: visible;

        &.progress-bar-general {
            background-color: $score-general;
        }
        &.progress-bar-specific {
            background-color: $score-specific;
        }
        &.progress-bar-score {
            background-color: $score-total;
        }
    }
}

// progress-% classes for simple usage

@for $i from 1 through 100 {
    .progress-#{$i} {
        width: percentage($i/100);
    }
}
