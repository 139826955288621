/* ========================================================================
     Component: typo
 ========================================================================== */

body {
    font-family: $font-family-base;
    color: #656565;
}
h1,
h2,
h3,
h4 {
    font-family: $font-family-sans-serif-bold;
}
h1,
h2,
h3 {
    color: $brand-text;
    font-weight: 600;
}

h1,
.h1 {
    font-size: $font-size-h1;
}
h2,
.h2 {
    font-size: $font-size-h2;
}
h3,
.h3 {
    font-size: $font-size-h3;
}
h4,
.h4 {
    font-size: $font-size-h4;
}
h5,
.h5 {
    font-size: $font-size-h5;
}
h6,
.h6 {
    font-size: $font-size-h6;
}

.introTitle {
    font-size: 16px;
    color: #393c50;
    font-weight: 600;
}
.p-inputtext,
.p-component {
    font-family: $font-family-base;
    font-size: $primeng-font-size;
}
