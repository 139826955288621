//
// Variables
// --------------------------------------------------
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300i,400,400i,600,600i');

//== Colors
//
//## Gray and brand colors for use across Bootstrap.

// Redefined Gray colors for theme
$gray-darker: #393c50;
$gray-dark: #7c7e81;
$gray-darkest: #1a1a1a;
$gray: #dde6e9;
$gray-light: #e4eaec;
$gray-lighter: #f5f5f5;
$gray-bg: #cdd6de;

// Classic brand colors

$brand-primary: #e83f4b;
$brand-primary-light: #f7c0d5;
$brand-primary-dark: #ca0e1b;
$brand-secondary: #e0b600;
$brand-secondary-light: #f1c711;
$brand-secondary-dark: #c9a500;
$brand-dark-blue: #393c50;
$brand-dark-blue-darker: #251c29;
$brand-text: #393c50;
$brand-text-white: #fff;
$brand-success: #5cb85c;
$brand-info: #76bce9;
$brand-warning: #ffc107;
$brand-danger: #e6223b;
$brand-badge-secondary: #f7c0d5;
$brand-light: #f3f3f4;

$border-color: rgb(227, 227, 227);
$disabled-color: #d7dddb;
$brand-dark-disabled: #6d767f;

// New brand colors
$brand-inverse: #7c7e81;
$brand-blue: #76bce9;
$brand-pink: #f7c0d5;
$brand-green: #37bc9b;
$brand-purple: #7266ba;
$brand-yellow: #fad732;
$brand-dark: #393c50;

// accreditation score color Variants
$score-total: #f5f5f5;
$score-general: #fee575;
$score-specific: #ffd004;

// Brand color Variants

$amountOfLight: 10%;

$brand-success-light: lighten($brand-success, $amountOfLight);
$brand-info-light: lighten($brand-info, $amountOfLight);
$brand-warning-light: lighten($brand-warning, $amountOfLight);
$brand-danger-light: lighten($brand-danger, $amountOfLight);
$brand-inverse-light: lighten($brand-inverse, $amountOfLight);
$brand-blue-light: lighten($brand-blue, $amountOfLight);
$brand-green-light: lighten($brand-green, $amountOfLight);
$brand-pink-light: lighten($brand-pink, $amountOfLight);
$brand-purple-light: lighten($brand-purple, $amountOfLight);
$brand-yellow-light: lighten($brand-yellow, $amountOfLight);
$brand-dark-light: lighten($brand-dark, $amountOfLight);

$brand-primary-dark: darken($brand-primary, $amountOfLight);
$brand-success-dark: darken($brand-success, $amountOfLight);
$brand-info-dark: #4c9bcf;
$brand-warning-dark: darken($brand-warning, $amountOfLight);
$brand-danger-dark: darken($brand-danger, $amountOfLight);
$brand-inverse-dark: darken($brand-inverse, $amountOfLight);
$brand-blue-dark: darken($brand-blue, $amountOfLight);
$brand-green-dark: darken($brand-green, $amountOfLight);
$brand-pink-dark: darken($brand-pink, $amountOfLight);
$brand-purple-dark: darken($brand-purple, $amountOfLight);
$brand-yellow-dark: darken($brand-yellow, $amountOfLight);
$brand-dark-dark: darken($brand-dark, $amountOfLight);

//== Scaffolding
//
//## Settings for some of the most global styles.

// Background color for `<body>`.
$body-bg: #cdd6de;
$header-bg: rgba(255, 255, 255, 0.4);
// Global text color on `<body>`
$text-color: #515253;

// Global textual link color.
$link-color: $brand-primary;
// Link hover color set via `darken()` function.
$link-hover-color: darken($link-color, 15%);
// Link hover decoration.
$link-hover-decoration: underline;

//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif: Axiforma-Regular, sans-serif;
$font-family-sans-serif-bold: Axiforma-SemiBold, sans-serif;

$font-family-serif: Roboto Slab Light, Georgia, 'Times New Roman', Times, serif;
// Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
$font-family-monospace: Menlo, Monaco, Consolas, 'Courier New', monospace;
$font-family-base: $font-family-sans-serif;

$font-size-base: 0.75 !default; // ~12px
$font-size-large: ceil(($font-size-base * 1.25)); // ~18px
$font-size-small: ceil(($font-size-base * 0.85)); // ~12px

$font-size-h1: 37px; // ~36px
$font-size-h2: 30px; // ~30px
$font-size-h3: 24px; // ~24px
$font-size-h4: 18px; // ~18px
$font-size-h5: $font-size-base;
$font-size-h6: 12px; // ~12px

$body-font-size: $font-size-base;
// Unit-less `line-height` for use in components like buttons.
$line-height-base: 1.528571429; // 20/14
// Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed: floor(($font-size-base * $line-height-base)); // ~20px

// By default, this inherits from the `<body>`.
$headings-font-family: inherit;
$headings-font-weight: 500;
$headings-line-height: 1.1;
$headings-color: inherit;

//== Iconography
//
//## Specify custom location and filename of the included Glyphicons icon font. Useful for those including Bootstrap via Bower.

// Load fonts from this directory.
$icon-font-path: '../fonts/';
// File name for all font files.
$icon-font-name: 'glyphicons-halflings-regular';
// Element ID within SVG icon file.
$icon-font-svg-id: 'glyphicons_halflingsregular';

//== Components
//
//## Define common padding and border radius sizes and more. Values based on 14px text and 1.428 line-height (~20px to start).

$padding-base-vertical: 6px;
$padding-base-horizontal: 16px;

$padding-large-vertical: 10px;
$padding-large-horizontal: 20px;

$padding-small-vertical: 5px;
$padding-small-horizontal: 10px;

$padding-xs-vertical: 1px;
$padding-xs-horizontal: 5px;

$line-height-large: 1.33;
$line-height-small: 1.5;

$border-radius: 16px;
$border-radius-base: 6px;
$border-radius-large: 9px;
$border-radius-small: 5px;
$border-radius-xxl: 12px;

$btn-border-radius: 24px;

// Global color for active items (e.g., navs or dropdowns).
$component-active-color: #fff;
// Global background color for active items (e.g., navs or dropdowns).
$component-active-bg: $brand-primary;

// Width of the `border` for generating carets that indicator dropdowns.
$caret-width-base: 4px;
// Carets increase slightly in size for larger components.
$caret-width-large: 5px;

//== Tables
//
//## Customizes the `.table` component with basic values, each used across all table variations.

// Padding for `<th>`s and `<td>`s.
$table-cell-padding: 8px;
// Padding for cells in `.table-condensed`.
$table-condensed-cell-padding: 5px;

// Default background color used for all tables.
$table-bg: transparent;
// Background color used for `.table-striped`.
$table-bg-accent: #fafbfc;
// Background color used for `.table-hover`.
$table-bg-hover: #fafbfc;
$table-bg-active: $table-bg-hover;

// Border color for table and cell borders.
$table-border-color: #eee;

$table-color: $brand-text;
$table-striped-color: $brand-text;
//== Buttons
//
//## For each of Bootstrap's buttons, define text, background and border color.
$btn-font-compact: 11px;
$btn-font-extra-compact: 9px;
$btn-font-weight: normal;

$btn-default-color: #333;
$btn-default-bg: #fff;
$btn-default-border: #eaeaea;

$btn-primary-color: #fff;
$btn-primary-bg: $brand-info;
$btn-primary-border: darken($btn-primary-bg, 1%);

$btn-success-color: #fff;
$btn-success-bg: $brand-success;
$btn-success-border: darken($btn-success-bg, 1%);

$btn-info-color: #fff;
$btn-info-bg: $brand-info;
$btn-info-border: darken($btn-info-bg, 1%);

$btn-warning-color: #fff;
$btn-warning-bg: $brand-warning;
$btn-warning-border: darken($btn-warning-bg, 1%);

$btn-danger-color: #fff;
$btn-danger-bg: $brand-danger;
$btn-danger-border: darken($btn-danger-bg, 1%);

$btn-link-disabled-color: $disabled-color;

// Allows for customizing button radius independently from global border radius
$btn-border-radius-base: $btn-border-radius;
$btn-border-radius-large: $btn-border-radius;
$btn-border-radius-small: $btn-border-radius;

$btn-padding-x: 20px;
$btn-padding-y: 10px;

$btn-padding-x-sm: 10px;
$btn-padding-y-sm: 5px;

$btn-padding-x-xs: 8px;
$btn-padding-y-xs: 2px;

//== Forms
//
//##

// `<input>` background color
$body-bg: #fff;
// `<input disabled>` background color
$body-bg-disabled: $gray-lighter;

// Text color for `<input>`s
$input-color: $gray-dark;
// `<input>` border color
$input-border: $gray;
// TODO: Rename `$input-border-radius` to `$input-border-radius-base` in v4
// Default `.form-control` border radius
$input-border-radius: $border-radius-base;
// Large `.form-control` border radius
$input-border-radius-large: $border-radius-large;
// Small `.form-control` border radius
$input-border-radius-small: $border-radius-small;

// Border color for inputs on focus
$input-border-focus: #66afe9;

// Placeholder text color
$input-placeholder-color: #b7bac9;

// Default `.form-control` height
$input-height-base: ($line-height-computed + ($padding-base-vertical * 2) + 2);
// Large `.form-control` height
$input-height-large: (ceil($font-size-large * $line-height-large) + ($padding-large-vertical * 2) + 2);
// Small `.form-control` height
$input-height-small: (floor($font-size-small * $line-height-small) + ($padding-small-vertical * 2) + 2);

$legend-color: $gray-dark;
$legend-border-color: #e5e5e5;

// Background color for textual input addons
$input-group-addon-bg: $gray-lighter;
// Border color for textual input addons
$input-group-addon-border-color: $input-border;

$input-font-weight: 800;
$input-color: $text-color;

//switch (toggle)
$form-check-input-checked-bg-color: $brand-blue;
$form-check-input-bg: #dcddde;
$form-check-input-border: yellow;
// Disabled cursor for form controls and buttons.
$cursor-disabled: not-allowed;

//== Dropdowns
//
//## Dropdown menu container and contents.

// Background for the dropdown menu.
$dropdown-bg: #fff;
// Dropdown menu `border-color`.
$dropdown-border: #e1e1e1;
// Dropdown menu `border-color` **for IE8**.
$dropdown-fallback-border: #ccc;
// Divider color for between dropdown items.
$dropdown-divider-bg: #e5e5e5;

// Dropdown link text color.
$dropdown-link-color: #5f5f5f;
// Hover color for dropdown links.
$dropdown-link-hover-color: darken($gray-dark, 5%);
// Hover background for dropdown links.
$dropdown-link-hover-bg: #fafbfc;

// Active dropdown menu item text color.
$dropdown-link-active-color: $component-active-color;
// Active dropdown menu item background color.
$dropdown-link-active-bg: $component-active-bg;

// Disabled dropdown menu item background color.
$dropdown-link-disabled-color: $gray-light;

// Text color for headers within dropdown menus.
$dropdown-header-color: $gray-light;

// Deprecated `$dropdown-caret-color` as of v3.1.0
$dropdown-caret-color: #000;

//-- Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.
//
// Note: These variables are not generated into the Customizer.

$zindex-navbar: 1000;
$zindex-dropdown: 1000;
$zindex-popover: 1060;
$zindex-tooltip: 1070;
$zindex-navbar-fixed: 1030;
$zindex-modal-background: 1040;
$zindex-modal: 1050;

//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
// Deprecated `$screen-xs` as of v3.0.1
$screen-xs: 480px;
// Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min: $screen-xs;
// Deprecated `$screen-phone` as of v3.0.1
$screen-phone: $screen-xs-min;

// Small screen / tablet
// Deprecated `$screen-sm` as of v3.0.1
$screen-sm: 768px;
$screen-sm-min: $screen-sm;
// Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet: $screen-sm-min;

// Medium screen / desktop
// Deprecated `$screen-md` as of v3.0.1
$screen-md: 992px;
$screen-md-min: $screen-md;
// Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop: $screen-md-min;

// Large screen / wide desktop
// Deprecated `$screen-lg` as of v3.0.1
$screen-lg: 1200px;
$screen-lg-min: $screen-lg;
// Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop: $screen-lg-min;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm-min - 1);
$screen-sm-max: ($screen-md-min - 1);
$screen-md-max: ($screen-lg-min - 1);

//== Grid system
//
//## Define your custom responsive grid.

// Number of columns in the grid.
$grid-columns: 12;
// Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width: 30px;
// Navbar collapse
// Point at which the navbar becomes uncollapsed.
$grid-float-breakpoint: $screen-sm-min;
// Point at which the navbar begins collapsing.
$grid-float-breakpoint-max: ($grid-float-breakpoint - 1);

//== Container sizes
//
//## Define the maximum width of `.container` for different screen sizes.

// Small screen / tablet
$container-tablet: ((720px + $grid-gutter-width));
// For `$screen-sm-min` and up.
$container-sm: $container-tablet;

// Medium screen / desktop
$container-desktop: ((940px + $grid-gutter-width));
// For `$screen-md-min` and up.
$container-md: $container-desktop;

// Large screen / wide desktop
$container-large-desktop: ((1140px + $grid-gutter-width));
// For `$screen-lg-min` and up.
$container-lg: $container-large-desktop;

//== Navbar
//
//##

// Basics of a navbar
$navbar-height: 70px;
$navbar-margin-bottom: $line-height-computed;
$navbar-border-radius: $border-radius-base;
$navbar-padding-horizontal: floor(($grid-gutter-width / 2));
$navbar-padding-vertical: (($navbar-height - $line-height-computed) / 2);
$navbar-collapse-max-height: 340px;

$navbar-default-color: #777;
$navbar-default-bg: #f8f8f8;
$navbar-default-border: darken($navbar-default-bg, 6.5%);

// Navbar links
$navbar-default-link-color: #fff;
$navbar-default-link-hover-color: #ddd;
$navbar-default-link-hover-bg: transparent;
$navbar-default-link-active-color: #ddd;
$navbar-default-link-active-bg: darken($navbar-default-bg, 6.5%);
$navbar-default-link-disabled-color: #ccc;
$navbar-default-link-disabled-bg: transparent;

// Navbar brand label
$navbar-default-brand-color: $navbar-default-link-color;
$navbar-default-brand-hover-color: darken($navbar-default-brand-color, 10%);
$navbar-default-brand-hover-bg: transparent;

// Navbar toggle
$navbar-default-toggle-hover-bg: #ddd;
$navbar-default-toggle-icon-bar-bg: #888;
$navbar-default-toggle-border-color: #ddd;

// Inverted navbar
// Reset inverted navbar basics
$navbar-inverse-color: $gray-light;
$navbar-inverse-bg: #222;
$navbar-inverse-border: darken($navbar-inverse-bg, 10%);

// Inverted navbar links
$navbar-inverse-link-color: $gray-light;
$navbar-inverse-link-hover-color: #fff;
$navbar-inverse-link-hover-bg: transparent;
$navbar-inverse-link-active-color: $navbar-inverse-link-hover-color;
$navbar-inverse-link-active-bg: darken($navbar-inverse-bg, 10%);
$navbar-inverse-link-disabled-color: #444;
$navbar-inverse-link-disabled-bg: transparent;

// Inverted navbar brand label
$navbar-inverse-brand-color: $navbar-inverse-link-color;
$navbar-inverse-brand-hover-color: #fff;
$navbar-inverse-brand-hover-bg: transparent;

// Inverted navbar toggle
$navbar-inverse-toggle-hover-bg: #333;
$navbar-inverse-toggle-icon-bar-bg: #fff;
$navbar-inverse-toggle-border-color: #333;

//== Navs
//
//##

//=== Shared nav styles
$nav-link-padding: 10px 15px;
$nav-link-hover-bg: transparent;

$nav-disabled-link-color: $gray-light;
$nav-disabled-link-hover-color: $gray-light;

$nav-open-link-hover-color: #fff;

//== Tabs
$nav-tabs-border-width: 0;
$nav-tabs-border-radius: 0;
$nav-tabs-border-color: #fff;

$nav-tabs-link-hover-border-color: #fff;

$nav-tabs-link-active-color: $brand-text;
$nav-tabs-active-link-hover-bg: #fff;
$nav-tabs-active-link-hover-color: #fff;
$nav-tabs-active-link-hover-border-color: #fff;
$nav-tabs-justified-link-border-color: #ddd;
$nav-tabs-justified-active-link-border-color: $body-bg;

//== Pills
$nav-pills-border-radius: $border-radius-base;
$nav-pills-active-link-hover-bg: $component-active-bg;
$nav-pills-active-link-hover-color: $component-active-color;

//== Pagination
//
//##

$pagination-color: $brand-dark-disabled;
$pagination-bg: #fff;
$pagination-border: #eee;

$pagination-hover-color: $brand-dark-blue;
$pagination-hover-bg: #fff;
$pagination-hover-border: $brand-primary;

$pagination-active-color: $brand-dark-blue;
$pagination-active-bg: #fff;
$pagination-active-border: $brand-primary;

$pagination-disabled-color: $brand-dark-disabled;
$pagination-disabled-bg: #fff;
$pagination-disabled-border: #eee;
$pagination-border-width: 0;
$pagination-padding-y: 4px;
//== Pager
//
//##

$pager-bg: $pagination-bg;
$pager-border: $pagination-border;
$pager-border-radius: 15px;

$pager-hover-bg: $pagination-hover-bg;

$pager-active-bg: $pagination-active-bg;
$pager-active-color: $pagination-active-color;

$pager-disabled-color: $pagination-disabled-color;

//== Jumbotron
//
//##

$jumbotron-padding: 30px;
$jumbotron-color: inherit;
$jumbotron-bg: #fcfcfc;
$jumbotron-heading-color: inherit;
$jumbotron-font-size: ceil(($font-size-base * 1.5));
$jumbotron-heading-font-size: ceil(($font-size-base * 4.5)) !default;

//== Form states and alerts
//
//## Define colors for form feedback states and, by default, alerts.

$state-success-text: $brand-success;
$state-success-bg: #dff0d8;
$state-success-border: darken(adjust-hue($state-success-bg, -10), 5%);

$state-info-text: $brand-info;
$state-info-bg: #d9edf7;
$state-info-border: darken(adjust-hue($state-info-bg, -10), 7%);

$state-warning-text: $brand-warning;
$state-warning-bg: #fcf8e3;
$state-warning-border: darken(adjust-hue($state-warning-bg, -10), 5%);

$state-danger-text: $brand-danger;
$state-danger-bg: #f2dede;
$state-danger-border: darken(adjust-hue($state-danger-bg, -10), 5%);

//== Tooltips
//
//##

// Tooltip max width
$tooltip-max-width: 200px;
// Tooltip text color
$tooltip-color: #fff;
// Tooltip background color
$tooltip-bg: #222;
$tooltip-opacity: 0.9;

// Tooltip arrow width
$tooltip-arrow-width: 5px;
// Tooltip arrow color
$tooltip-arrow-color: $tooltip-bg;

//== Popovers
//
//##

// Popover body background color
$popover-bg: #fff;
// Popover maximum width
$popover-max-width: 276px;
// Popover border color
$popover-border-color: #e1e1e1;
// Popover fallback border color
$popover-fallback-border-color: #ccc;

// Popover title background color
$popover-title-bg: #fafafa;

// Popover arrow width
$popover-arrow-width: 10px;
// Popover arrow color
$popover-arrow-color: #fafafa;

// Popover outer arrow width
$popover-arrow-outer-width: ($popover-arrow-width + 1);
// Popover outer arrow color
$popover-arrow-outer-color: $popover-border-color;
// Popover outer arrow fallback color
$popover-arrow-outer-fallback-color: darken($popover-fallback-border-color, 20%);

//== Labels
//
//##

// Default label background color
$label-default-bg: $gray-light;
// Primary label background color
$label-primary-bg: $brand-primary;
// Success label background color
$label-success-bg: $brand-success;
// Info label background color
$label-info-bg: $brand-info;
// Warning label background color
$label-warning-bg: $brand-warning;
// Danger label background color
$label-danger-bg: $brand-danger;

// Default label text color
$label-color: #fff;
// Default text color of a linked label
$label-link-hover-color: #fff;

//== Modals
//
//##

// Padding applied to the modal body
$modal-inner-padding: 15px;

// Padding applied to the modal title
$modal-title-padding: 15px;
// Modal title line-height
$modal-title-line-height: $line-height-base;

// Background color of modal content area
$modal-content-bg: #fff;
// Modal content border color
$modal-content-border-color: rgba(0, 0, 0, 0.2);
// Modal content border color **for IE8**
$modal-content-fallback-border-color: #999;

// Modal backdrop background color
$modal-backdrop-bg: #000;
// Modal backdrop opacity
$modal-backdrop-opacity: 0.5;
// Modal header border color
$modal-header-border-color: #e5e5e5;
// Modal footer border color
$modal-footer-border-color: $modal-header-border-color;

$modal-lg: 900px;
$modal-md: 600px;
$modal-sm: 300px;

//== Alerts
//
//## Define alert colors, border radius, and padding.

$alert-padding: 8px;
$alert-border-radius: $border-radius-small;
$alert-link-font-weight: bold;

$alert-success-bg: $brand-success;
$alert-success-text: #fff;
$alert-success-border: rgba(0, 0, 0, 0.1);

$alert-info-bg: $brand-info;
$alert-info-text: #fff;
$alert-info-border: rgba(0, 0, 0, 0.1);

$alert-warning-bg: $brand-warning;
$alert-warning-text: #fff;
$alert-warning-border: rgba(0, 0, 0, 0.1);

$alert-danger-bg: $brand-danger;
$alert-danger-text: #fff;
$alert-danger-border: rgba(0, 0, 0, 0.1);

//== Progress bars
//
//##

// Background color of the whole progress component
$progress-bg: #f1f1f1;
// Progress bar text color
$progress-bar-color: #fff;
// Variable for setting rounded corners on progress bar.
$progress-border-radius: $border-radius-base;

// Default progress bar color
$progress-bar-bg: $brand-primary;
// Success progress bar color
$progress-bar-success-bg: $brand-success;
// Warning progress bar color
$progress-bar-warning-bg: $brand-warning;
// Danger progress bar color
$progress-bar-danger-bg: $brand-danger;
// Info progress bar color
$progress-bar-info-bg: $brand-info;

//== List group
//
//##

// Background color on `.list-group-item`
$list-group-bg: #fff;
// `.list-group-item` border color
$list-group-border: $gray-light;
// List group border radius
$list-group-border-radius: $border-radius-base;

// Background color of single list items on hover
$list-group-hover-bg: #fafbfc;
// Text color of active list items
$list-group-active-color: $component-active-color;
// Background color of active list items
$list-group-active-bg: $component-active-bg;
// Border color of active list elements
$list-group-active-border: $list-group-active-bg;
// Text color for content within active list items
$list-group-active-text-color: lighten($list-group-active-bg, 40%);

// Text color of disabled list items
$list-group-disabled-color: $gray-light;
// Background color of disabled list items
$list-group-disabled-bg: $gray-lighter;
// Text color for content within disabled list items
$list-group-disabled-text-color: $list-group-disabled-color;

$list-group-link-color: #555;
$list-group-link-hover-color: $list-group-link-color;
$list-group-link-heading-color: #333;

//== Panels
//
//##
$panel-global-border: #cfdbe2;

$panel-bg: #fff;
$panel-body-padding: 15px;
$panel-heading-padding: 10px 15px;
$panel-footer-padding: $panel-heading-padding;
$panel-border-radius: $border-radius-base;

// Border color for elements within panels
$panel-inner-border: #eee;
$panel-footer-bg: #fafafa;

$panel-default-text: #444;
$panel-default-border: $panel-global-border;
$panel-default-heading-bg: #fff;

$panel-primary-text: #fff;
$panel-primary-border: $panel-global-border;
$panel-primary-heading-bg: $brand-primary;

$panel-success-text: #fff;
$panel-success-border: $panel-global-border;
$panel-success-heading-bg: $brand-success;

$panel-info-text: #fff;
$panel-info-border: $panel-global-border;
$panel-info-heading-bg: $brand-info;

$panel-warning-text: #fff;
$panel-warning-border: $panel-global-border;
$panel-warning-heading-bg: $brand-warning;

$panel-danger-text: #fff;
$panel-danger-border: $panel-global-border;
$panel-danger-heading-bg: $brand-danger;

// Card
$card-border-radius: $border-radius;
$card-cap-bg: #fff;
$card-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
$card-color: $brand-text;

//== Thumbnails
//
//##

// Padding around the thumbnail image
$thumbnail-padding: 2px;
// Thumbnail background color
$thumbnail-bg: #e1e2e3;
// Thumbnail border color
$thumbnail-border: #e1e2e3;
// Thumbnail border radius
$thumbnail-border-radius: $border-radius-small;

// Custom text color for thumbnail captions
$thumbnail-caption-color: $text-color;
// Padding around the thumbnail caption
$thumbnail-caption-padding: 9px;

//== Wells
//
//##

$well-bg: #fff;
$well-border: darken($well-bg, 7%);

//== Badges
//
//##

$badge-color: #fff;
// Linked badge text color on hover
$badge-link-hover-color: #fff;
$badge-bg: $gray-light;

// Badge text color in active nav link
$badge-active-color: $link-color;
// Badge background color in active nav link
$badge-active-bg: #fff;

$badge-font-weight: normal;
$badge-line-height: 1;
$badge-border-radius: 10px;
$badge-font-size: 10px;
$badge-light-success-bg: #e7fced;
$badge-light-danger-bg: #ffebed;
$badge-light-info-bg: #dff2fd;
$badge-light-warning-bg: #fdfbe8;

//== Breadcrumbs
//
//##

$breadcrumb-padding-vertical: 12px;
$breadcrumb-padding-horizontal: 15px;
// Breadcrumb background color
$breadcrumb-bg: #fafafa;
// Breadcrumb text color
$breadcrumb-color: #c1c2c3;
// Text color of current page in the breadcrumb
$breadcrumb-active-color: $text-color;
// Textual separator for between breadcrumb elements
$breadcrumb-separator: '/';

//== Carousel
//
//##

$carousel-text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);

$carousel-control-color: #fff;
$carousel-control-width: 15%;
$carousel-control-opacity: 0.5;
$carousel-control-font-size: 20px;

$carousel-indicator-active-bg: #fff;
$carousel-indicator-border-color: #fff;

$carousel-caption-color: #fff;

//== Close
//
//##

$close-font-weight: bold;
$close-color: #000;
$close-text-shadow: 0 1px 0 #fff;

//== Code
//
//##

$code-color: #c7254e;
$code-bg: #f9f2f4;

$kbd-color: #fff;
$kbd-bg: #333;

$pre-bg: #f5f5f5;
$pre-color: $gray-dark;
$pre-border-color: #ccc;
$pre-scrollable-max-height: 340px;

//== Type
//
//##

// Horizontal offset for forms and lists.
$component-offset-horizontal: 180px;
// Text muted color
$text-muted: #909fa7;
// Abbreviations and acronyms border color
$abbr-border-color: $gray-dark;
// Headings small color
$headings-small-color: $gray-dark;
// Blockquote small color
$blockquote-small-color: $gray-dark;
// Blockquote font size
$blockquote-font-size: ($font-size-base * 1.25);
// Blockquote border color
$blockquote-border-color: $gray-lighter;
// Page header border color
$page-header-border-color: $gray-lighter;
// Width of horizontal description list titles
$dl-horizontal-offset: $component-offset-horizontal;
// Horizontal line color.
$hr-border-color: $gray-lighter;

$widget-margin: 20px;

//
// Imported vars from theme-a
// ----------------------------------------------------
// LAYOUT
$content-bg: #cdd6de;
$aside-bg: transparent;
// NAVBAR TOP
$nav-top-bg: $brand-primary;
$nav-top-bg-start: $nav-top-bg;
$nav-top-bg-end: $brand-primary;
$nav-header-bg: transparent;

$nav-top-item-mobile: $nav-top-bg;
$nav-top-item-mobile-active: darken($nav-top-item-mobile, 20%);

$nav-top-item-desktop: #fff;
$nav-top-item-desktop-active: darken($nav-top-item-desktop, 20%);

$nav-link-font-size: 13px;
$navbar-light-active-color: $brand-primary;
// SIDEBAR

$sidebar-bg: $aside-bg;

$sidebar-item-color: #cbcfd3;
$sidebar-item-color-active: $nav-top-bg;
$sidebar-item-bg-active: darken($sidebar-bg, 1%);

$sidebar-icon-color: inherits;
$sidebar-icon-color-active: $nav-top-bg;

$sidebar-bullet-color: rgba(0, 0, 0, 0.25);
$sidebar-bullet-color-active: $sidebar-icon-color-active;

$sidebar-heading-color: #919da8;
$sidebar-label-color: $sidebar-item-color;

$submenu-header-hg: 40px; // Submenu header when hover collapse sidebar
$sidebar-item-border-wd: 3px;
$scrollbar-wd: 17px;

$tablet-landscape: 1025px;

// OFFSIDEBAR

$offsidebar-bg: #fff;
$offsidebar-border-color: greyscale(darken($offsidebar-bg, 20%));
$offsidebar-color: $text-color;

//PRIMENG

$primeng-font-size: 12px;

$fontFamily: $font-family-base;
$fontSize: $primeng-font-size;
$inputTextColor: red;
