@use 'node_modules/ag-grid-community/styles' as ag;

@include ag.grid-styles(
    (
        theme: material,
        material-accent-color: $brand-blue,
        material-primary-color: $brand-blue,
    )
);
.ag-theme-material {
    .ag-row-even {
        background-color: #f3f3f4;
    }
}
.ag-theme-material {
    --ag-grid-size: 6px;
    --ag-font-size: 0.688rem;
    //  --ag-header-height: calc(var(--ag-grid-size) * 5);
    //  --ag-row-height: calc(var(--ag-grid-size) * 5);
    --ag-cell-horizontal-padding: 6px;
    --ag-font-family: $font-family-sans-serif;
    --ag-foreground-color: $brand-text;
    --ag-data-color: #1d1c2b;

    .ag-cell-wrap-text {
        .ag-cell-value {
            line-height: 15px !important;
            word-break: normal; /* prevent words from breaking */
            padding-top: 10px; /* space top */
            padding-bottom: 10px; /* space bottom */
        }
    }
}

.ag-theme-material .ag-layout-auto-height .ag-center-cols-viewport,
.ag-theme-material .ag-layout-auto-height .ag-center-cols-container,
.ag-theme-material .ag-layout-print .ag-center-cols-viewport,
.ag-theme-material .ag-layout-print .ag-center-cols-container,
.ag-theme-material-dark .ag-layout-auto-height .ag-center-cols-viewport,
.ag-theme-material-dark .ag-layout-auto-height .ag-center-cols-container,
.ag-theme-material-dark .ag-layout-print .ag-center-cols-viewport,
.ag-theme-material-dark .ag-layout-print .ag-center-cols-container,
.ag-theme-material-auto-dark .ag-layout-auto-height .ag-center-cols-viewport,
.ag-theme-material-auto-dark .ag-layout-auto-height .ag-center-cols-container,
.ag-theme-material-auto-dark .ag-layout-print .ag-center-cols-viewport,
.ag-theme-material-auto-dark .ag-layout-print .ag-center-cols-container {
    min-height: 30px;
}
//use this to align texts in the middle
.ag-cell-wrapper {
    min-height: 100%;
}
.ag-theme-material .ag-filter-toolpanel-header,
.ag-theme-material .ag-filter-toolpanel-search,
.ag-theme-material .ag-status-bar,
.ag-theme-material .ag-header-row,
.ag-theme-material .ag-panel-title-bar-title,
.ag-theme-material .ag-multi-filter-group-title-bar,
.ag-theme-material-dark .ag-filter-toolpanel-header,
.ag-theme-material-dark .ag-filter-toolpanel-search,
.ag-theme-material-dark .ag-status-bar,
.ag-theme-material-dark .ag-header-row,
.ag-theme-material-dark .ag-panel-title-bar-title,
.ag-theme-material-dark .ag-multi-filter-group-title-bar,
.ag-theme-material-auto-dark .ag-filter-toolpanel-header,
.ag-theme-material-auto-dark .ag-filter-toolpanel-search,
.ag-theme-material-auto-dark .ag-status-bar,
.ag-theme-material-auto-dark .ag-header-row,
.ag-theme-material-auto-dark .ag-panel-title-bar-title,
.ag-theme-material-auto-dark .ag-multi-filter-group-title-bar {
    color: #1d1c2b;
    font-weight: 400;
    font-size: var(--ag-font-size);
}

/*!
 * table-grid (http://mdo.github.io/table-grid)
 * Released under MIT, (c) 2014 Mark Otto
 */

/*
 * The Grid
 */

@media only screen and (min-width: $mq-tablet) {
    /* Add `.table-grid` for the table */
    .table-grid {
        display: table;
        width: 100%;
        height: 100%;
        table-layout: fixed;
    }

    /* Add `.col` for the table cells, or columns */
    .col {
        display: table-cell;
        vertical-align: top;
        //  height: 100%;
        &.col-full-center {
            vertical-align: middle;
        }
    }

    /* Set the widths */
    .col-1 {
        width: 8.333333%;
    }
    .col-2 {
        width: 16.666667%;
    }
    .col-3 {
        width: 25%;
    }
    .col-4 {
        width: 33.333333%;
    }
    .col-5 {
        width: 41.666667%;
    }
    .col-6 {
        width: 50%;
    }
    .col-7 {
        width: 58.333333%;
    }
    .col-8 {
        width: 66.666667%;
    }
    .col-9 {
        width: 75%;
    }
    .col-10 {
        width: 83.333333%;
    }
    .col-11 {
        width: 91.666667%;
    }
    .col-12 {
        width: 100%;
    }

    .col-xxs {
        width: $wd-xxs;
    }
    .col-xs {
        width: $wd-xs;
    }
    .col-sm {
        width: $wd-sm;
    }
    .col-sd {
        width: $wd-sd;
    }
    .col-md {
        width: $wd-md;
    }
    .col-lg {
        width: $wd-lg;
    }
    .col-xl {
        width: $wd-xl;
    }
    .col-xxl {
        width: $wd-xxl;
    }
    .col-wide {
        width: $wd-wide;
    }
    .col-auto {
        width: $wd-auto;
    }

    /* Padded columns */
    .table-grid-padded {
        margin-left: -1rem;
        margin-right: -1rem;
    }
    .table-grid-padded .table-grid {
        border-spacing: 1rem 0;
    }
}

@media only screen and (max-width: $mq-up-to-desktop) {
    .table-grid-desktop {
        &.table-grid,
        .col {
            display: block;
            width: auto;
        }
    }
}

/*
 * Vertically center grid content
 *
 * Requires content within the column to be inline or inline-block.
 */

.table-grid-align-middle .col {
    vertical-align: middle;
}
