/* ========================================================================
   Component: pagination
 ========================================================================== */

.page-link.active,
.active > a.page-link {
    border-bottom-width: 4px;
    border-bottom-color: $brand-primary;
    color: $brand-text;
    font-weight: 800;
}
.pagination .pagination-page > a.page-link {
    &:hover,
    &:focus {
        border-bottom-width: 4px;
        border-bottom-color: $brand-primary;
        color: $brand-text;
        font-weight: 800;
    }
}

.pagination .page-item > a.page-link {
    &:focus {
        box-shadow: none;
    }
}
