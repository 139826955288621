/* ========================================================================
     Component: steps
 ========================================================================== */

$pm-steps-disabled-bg: #aab3bd;
$pm-steps-disabled-color: #e1e6eb;
.p-steps {
    font-family: $font-family-base;
    font-size: 13px;
}
.p-steps .p-steps-item {
    opacity: 1;
    &:before {
        border-top: 3px solid $pm-steps-disabled-bg;
        top: 50%;
        left: 50%;
    }
    flex: 1 1;
    display: grid;
}
.p-steps .p-steps-item:last-child:before {
    left: -50%;
}
.p-steps .p-steps-item:last-child {
    &.p-highlight:before {
        border-top: 3px solid $brand-dark;
    }
}
.p-steps .p-steps-item .p-menuitem-link .p-steps-number {
    font-family: 'icomoon' !important;
    border: $brand-dark;
    border-radius: 50px;
    text-indent: -99px;
    background: $pm-steps-disabled-color;
    box-shadow: inset 0px 0px 0px 7px $pm-steps-disabled-bg;
    min-width: 1.76rem;
    height: 1.76rem;
    color: white;
    &:before {
        position: absolute;
        vertical-align: middle;
        font-weight: 900;
        text-indent: 0;
        font-size: 13px;
    }
}
.p-steps .p-steps-item .p-menuitem-link .p-steps-title {
    color: $pm-steps-disabled-bg;
}
.p-steps .p-steps-item.p-highlight .p-steps-title {
    font-weight: 400;
    color: $brand-dark;
}
.p-steps .p-steps-item.p-highlight .p-steps-number {
    background: white;
    box-shadow: inset 0px 0px 0px 7px $brand-dark;
    font-weight: 400;
}

@for $i from 1 through 11 {
    .p-steps.completed-#{$i} .p-steps-item:nth-child(-n + #{$i}) {
        &:before {
            border-top: 3px solid $brand-dark;
        }
        .p-menuitem-link .p-steps-number {
            background: $brand-dark;
            box-shadow: none;
            &:before {
                content: '\e980';
            }
        }
        .p-menuitem-link .p-steps-title {
            color: $brand-dark;
        }
    }
}

@media (max-width: $screen-sm-max) {
    .p-steps .p-steps-item {
        margin-top: 10px;
        &:not(.p-highlight) .p-menuitem-link .p-steps-title {
            color: transparent !important;
        }
    }
    .p-steps .p-steps-item.p-highlight .p-steps-title {
        position: absolute;
        bottom: 0;
    }
    .p-steps .p-steps-item:last-child {
        &.p-highlight .p-steps-title {
            right: 20px;
        }
    }
    .p-steps .p-steps-item:first-child {
        &.p-highlight .p-steps-title {
            left: 20px;
        }
    }
}
