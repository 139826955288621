/* ========================================================================
     Component: tag
 ========================================================================== */

// color variation
// -------------------------------------------------------------------------

.tag-success {
    @include make-bg($brand-success, $brand-text-white);
}
.tag-info {
    @include make-bg($brand-info, $brand-text-white);
}
.tag-warning {
    @include make-bg($brand-warning, $brand-text-white);
}
.tag-danger {
    @include make-bg($brand-primary, $brand-text-white);
}

// light
.tag-success-light {
    @include make-bg($badge-light-success-bg, $gray-darker);
}
.tag-info-light {
    @include make-bg($badge-light-info-bg, $gray-darker);
}
.tag-warning-light {
    @include make-bg($badge-light-warning-bg, $gray-darker);
}
.tag-danger-light {
    @include make-bg($badge-light-danger-bg, $gray-darker);
}
.tag-outline {
    @include make-bg(transparent, $gray-darker);
    border-color: $disabled-color;
    border-color: #d7dddb;
    border-width: 1px;
    border-style: solid;
}
.tag-grey {
    @include make-bg(#f3f3f4, $gray-darker);
}

// Accreditation score progress

.tag-total {
    @include make-bg($score-total, $gray-darker);
    border: $disabled-color 1px solid;
}
.tag-general {
    @include make-bg($score-general, $gray-darker);
}
.tag-specific {
    @include make-bg($score-specific, $gray-darker);
}

//----- Course ------

//Course difficulty level
// -------------------------------------------------------------------------

@for $i from 1 through 3 {
    .difficulty-level-#{$i} {
        padding: 6px 10px 6px 10px;

        &::before {
            content: '';
            width: 1.3em;
            height: 13px;
            display: inline-block;
            vertical-align: -3px;
            margin-right: 5px;
            padding-top: 0px;
            background-repeat: no-repeat;
            background-image: url('/assets/images/icon-level-#{$i}.svg');
        }
    }
}

//Course learning type
// -------------------------------------------------------------------------
@for $i from 1 through 10 {
    .learning-type-#{$i}::before {
        font-size: 18px;
        padding-right: 0.25rem !important;
        vertical-align: -4px;
        font-family: 'Phosphor-Light' !important;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        letter-spacing: 0;
        font-feature-settings: 'liga';
        font-variant-ligatures: discretionary-ligatures;
        -webkit-font-smoothing: antialiased;
        color: #e83f4b;
    }
}
//seminar
.learning-type-1::before,
//webinar
 .learning-type-2::before,
//blended
 .learning-type-4::before,
//seminar
 .learning-type-7::before,
//webinar
 .learning-type-8::before,
//blended
 .learning-type-10::before {
    content: '\e0e6';
}

//elearning
.learning-type-3::before,
//elearning-distance
 .learning-type-5::before,
//elearning-webinar
 .learning-type-6::before,
//elearning
 .learning-type-9::before {
    content: '\e586';
}
