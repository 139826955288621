//
// Utilities classes to simplify
// components constructions
// ------------------------------

$margin-base: 10px;
$margin-sm: 5px;
$margin-lg: 15px;
$margin-xl: 30px;

$padding-xl: 30px;
$padding-lg: 15px;
$padding-base: 10px;
$padding-sm: 5px;

$text-alpha: rgba(255, 255, 255, 0.5);
$text-alpha-inverse: rgba(0, 0, 0, 0.5);

$text-sm: $font-size-base * 0.85;
$text-md: $font-size-base * 1.9;
$text-lg: $font-size-base * 3;

$border-color: rgba(0, 0, 0, 0.12);

$wd-xxs: 60px;
$wd-xs: 90px;
$wd-sm: 150px;
$wd-sd: 200px; // sd == standard
$wd-md: 240px;
$wd-lg: 280px;
$wd-xl: 320px;
$wd-xxl: 360px;
$wd-wide: 100%;
$wd-auto: auto;
$wd-zero: 0;

// Background helpers
//  Generates classes for
//    .brand
//    .brand-light
//    .brand-dark

$colors: (
    'primary-light': $brand-primary-light,
    'primary': $brand-primary,
    'primary-dark': $brand-primary-dark,
    'success-light': $brand-success-light,
    'success': $brand-success,
    'success-dark': $brand-success-dark,
    'info-light': $brand-info-light,
    'info': $brand-info,
    'info-dark': $brand-info-dark,
    'warning-light': $brand-warning-light,
    'warning': $brand-warning,
    'warning-dark': $brand-warning-dark,
    'danger-light': $brand-danger-light,
    'danger': $brand-danger,
    'danger-dark': $brand-danger-dark,
    'green-light': $brand-green-light,
    'green': $brand-green,
    'green-dark': $brand-green-dark,
    'pink-light': $brand-pink-light,
    'pink': $brand-pink,
    'pink-dark': $brand-pink-dark,
    'purple-light': $brand-purple-light,
    'purple': $brand-purple,
    'purple-dark': $brand-purple-dark,
    'inverse-light': $brand-inverse-light,
    'inverse': $brand-inverse,
    'inverse-dark': $brand-inverse-dark,
    'yellow-light': $brand-yellow-light,
    'yellow': $brand-yellow,
    'yellow-dark': $brand-yellow-dark,
);

@function color($key) {
    @return map-get($colors, $key);
}

@mixin bgcolor-variant($bgcolor, $text-color: #fff) {
    $amountOfLight: 10%;
    & {
        background-color: color($bgcolor) !important;
        color: $text-color !important;
    }
    &-light {
        $col: color($bgcolor + '-light');
        background-color: #{$col} !important;
        color: $text-color !important;
    }
    &-dark {
        $col: color($bgcolor + '-dark');
        background-color: #{$col} !important;
        color: $text-color !important;
    }
    small {
        color: inherit;
    }
}

@mixin make-bg($bgcolor, $text-color: #fff) {
    background-color: $bgcolor !important;
    color: $text-color !important;
}

// Common thumbnail sizes (ideal for images)

@mixin thumnbail-size($sz: 8px) {
    width: $sz !important;
    height: $sz !important;
}
