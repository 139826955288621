/* ========================================================================
   Component: breadcrumbs
 ========================================================================== */

.breadcrumb {
    font-weight: normal;
    border-radius: 0;
    color: $text-muted;
    padding: 10px 20px;
}

.content-heading {
    // Breadcrumb next to view title
    + .breadcrumb {
        margin: -25px -25px 20px -20px;
        background-color: $content-heading-bg;
        border-top: 1px solid $content-heading-border;
        border-bottom: 1px solid $content-heading-border;
    }
    // Breadcrumb below title
    > .breadcrumb {
        background: transparent;
        font-size: 13px;
        border: 0;
        padding: 10px 10px 0 0;
        margin-bottom: 0;
        // Breadcrumb right aligned
        &.float-end {
            margin: -2px 0 0;
        }
    }
}

.breadcrumb {
    background-color: transparent;
    .breadcrumb-item {
        font-size: 13px;
        a {
            color: $brand-dark;
            text-decoration: none;
            @include transition($nav-link-transition);

            &:hover,
            &:focus {
                color: $brand-primary-dark;
                text-decoration: none;
            }

            // Disabled state lightens text
            &.disabled {
                color: $brand-dark-disabled;
                pointer-events: none;
                cursor: default;
            }
        }
    }
}
