/* ========================================================================
     Component: button-extra
 ========================================================================== */

//
// Extension for Bootstrap buttons
// ------------------------------------

// BUTTONS LABEL mixin
// --------------------------------------------------

@mixin button-label-size($padding-vertical, $padding-horizontal, $border-radius) {
    padding: $padding-vertical $padding-horizontal;
    left: (-1 * $padding-horizontal);
    border-radius: ($border-radius - 1px) 0 0 ($border-radius - 1px);

    &.btn-label-right {
        left: auto;
        right: (-1 * $padding-horizontal);
        border-radius: 0 ($border-radius - 1px) ($border-radius - 1px) 0;
    }
}

@mixin change-button-color($normal, $light, $dark, $text: #fff) {
    background-color: $normal;
    color: $text;
    border-color: $normal;
    transition: background-color 300ms;

    &:hover,
    &:focus {
        color: $text;
        border-color: $light;
        background-color: $light;
    }

    &:active,
    &:active:focus {
        color: $text;
        border-color: $dark;
        background-color: $dark;
    }
}

.btn-accent {
    &:not(.btn-outline) {
        //  @include change-button-color($brand-pink, $brand-pink-light, $brand-pink-dark, #fff);
    }
}

.btn-back {
    @include change-button-color(transparent, $gray-lighter, $gray-darker, $brand-primary);
    border-color: $brand-primary !important;

    &:hover,
    &:focus,
    &:active,
    &:active:focus {
        border-color: $brand-primary;
    }
}

// Flat square button
.btn-flat {
    border-bottom-width: 1px;
    border-radius: 0;
    box-shadow: 0 0 0 #000;
}

// Extra size
.btn-xl {
    padding: 20px 16px;
    font-size: 18px;
}

// Button square
.btn-square {
    border-radius: 0;
}

// Button pill

.btn-pill-left {
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    padding-left: 18px;
}

.btn-pill-right {
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    padding-right: 18px;
}

// Rounded buttons

.btn-oval {
    @extend .btn-pill-right;
    @extend .btn-pill-left;
}

// Labels for buttons
// --------------------------------------------------

.btn-labeled {
    padding-top: 0;
    padding-bottom: 0;
}

.btn-label {
    position: relative;
    background: transparent;
    background: rgba(0, 0, 0, 0.05);
    display: inline-block;
    @include button-label-size($padding-base-vertical, $padding-base-horizontal, $border-radius-base);
}

.btn-lg .btn-label {
    @include button-label-size($padding-large-vertical, $padding-large-horizontal, $border-radius-large);
}

.btn-sm .btn-label {
    @include button-label-size($padding-small-vertical, $padding-small-horizontal, $border-radius-small);
}

.btn-xs .btn-label {
    @include button-label-size(1px, 5px, $border-radius-small);
}

// Fixed width buttons
// --------------------------------------------------

$btn-fw-default: 80px;
$btn-fw-sm: 40px;
$btn-fw-md: 60px;
$btn-fw-lg: 140px;

.btn-fw {
    min-width: $btn-fw-default;

    &.btn-sm {
        min-width: $btn-fw-sm;
    }

    &.btn-md {
        min-width: $btn-fw-md;
    }

    &.btn-lg {
        min-width: $btn-fw-lg;
    }
}

// Button
// -----------------------------------

.btn {
    border-radius: $btn-border-radius-base;
    border-style: solid;
    border-width: 1px;

    &:not(.btn-outline):disabled {
        pointer-events: none;
        background-color: $disabled-color;
        border-color: $disabled-color;
        color: $brand-text-white;
        opacity: 1;
    }
}

// Button Compact

.btn-compact {
    @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $btn-font-compact, $btn-border-radius-small);
    font-size: $btn-font-compact;
}

.btn-extra-compact {
    @include button-size(1px, $btn-padding-x-xs, $btn-font-extra-compact, $btn-border-radius-small);
    font-size: $btn-font-extra-compact;
    padding: 4px 8px;
}

.btn-icon {
    @include button-size(0, 1px, $btn-font-compact, 0);
    font-size: $btn-font-compact;
    border-width: 0 !important;
    border: none;
    padding: 0 1px;
    background-color: inherit;
    color: inherit;

    &:hover,
    &:focus,
    &:active,
    &:active:focus {
        color: $brand-primary-dark !important;
        border-width: 0 !important;
        background-color: inherit !important;
    }
    &:disabled {
        pointer-events: none;
        border-width: 0 !important;
        background-color: inherit !important;
        color: $disabled-color !important;
        opacity: 1;
    }
}
.btn-link {
    background-color: inherit;
    color: $brand-primary;

    &:hover,
    &:focus,
    &:active,
    &:active:focus {
        color: $brand-primary-dark !important;
        border-width: 0 !important;
        background-color: inherit !important;
    }
}

// Button Default
// -----------------------------------

.btn-primary:not(.btn-outline) {
    @include change-button-color($brand-primary, $brand-primary-dark, $brand-primary-dark, $brand-text-white);
}

.btn-info:not(.btn-outline),
.btn-secondary:not(.btn-outline) {
    @include change-button-color($brand-info, $brand-info-dark, $brand-info-dark, $brand-text-white);
}

.btn-dark:not(.btn-outline),
.btn-default:not(.btn-outline) {
    @include change-button-color($brand-dark-blue, $brand-dark-blue-darker, $brand-dark-blue-darker, $brand-text-white);
}

.btn-grey:not(.btn-outline) {
    @include change-button-color($gray-bg, $gray, $gray, $gray-darker);
}

.btn-grey-dark:not(.btn-outline) {
    @include change-button-color($gray-dark, $gray-darkest, $gray-darkest, $gray-darkest);
}

.btn-light:not(.btn-outline) {
    @include change-button-color($brand-light, $gray, $gray, $gray-darker);
}

.btn-danger:not(.btn-outline) {
    @include change-button-color($brand-danger, $brand-danger-dark, $brand-danger-dark, $brand-text-white);
}

//button group (toggle buttons)
// -----------------------------------

.btn-group {
    .btn:not(.active):not(:hover) {
        background-color: transparent !important;
    }

    .btn-info:not(.active):not(:hover),
    .btn-secondary:not(.active):not(:hover) {
        color: $brand-info !important;
        border-color: $brand-info !important;
    }

    .btn-dark:not(.active):not(:hover),
    .btn-default:not(.active):not(:hover) {
        color: $brand-dark-blue !important;
        border-color: $brand-dark-blue !important;
    }

    .btn-danger:not(.active):not(:hover) {
        color: $brand-danger !important;
        border-color: $brand-danger !important;
    }
}

.btn-toggle {
    min-height: 70px;
    border-radius: 10px;
    width: 100%;

    &:not(.btn-outline) {
        @include change-button-color(#f3f3f4, $gray, $gray, $gray-darker);
    }
    &.checked,
    &.active,
    &.active:hover,
    &.active:focus {
        background-color: $brand-info;
        color: #fff;
    }
}

// Button Outline
// -----------------------------------

.btn-outline {
    background-color: transparent;

    &.btn-primary {
        @include button-outline-variant($brand-primary, $brand-primary-dark, transparent, $brand-primary-dark, $brand-primary-dark);
    }

    &.btn-secondary,
    &.btn-info {
        @include button-outline-variant($brand-info, $brand-info-dark, transparent, $brand-info-dark, $brand-info-dark);
        border-color: $brand-info;
        color: $brand-info;
        &:hover,
        &:focus,
        &:active,
        &:active:focus {
            color: $brand-info-dark !important;
            border-color: $brand-info-dark !important;
        }
    }

    &.btn-default,
    &.btn-dark {
        @include button-outline-variant($brand-dark-blue, $brand-dark-blue-darker, transparent, $brand-dark-blue-darker, $brand-dark-blue-darker);
        border-color: $brand-dark-blue;
        color: $brand-dark-blue;
        &:hover,
        &:focus,
        &:active,
        &:active:focus {
            color: $brand-dark-blue-darker !important;
            border-color: $brand-dark-blue-darker !important;
        }
    }

    &.btn-grey {
        @include button-outline-variant($gray-bg, $gray-darker, transparent, $gray-darker, $gray-darker);
        border-color: $gray-bg;
        color: $gray-bg;
    }

    &.btn-grey-dark {
        @include button-outline-variant($gray-dark, $gray-darkest, transparent, $gray-darkest, $gray-darkest);
    }

    &.btn-light {
        @include button-outline-variant($brand-light, $gray-darker, transparent, $gray-darker, $gray-darker);
    }

    &:disabled {
        pointer-events: none;
        color: $disabled-color;
        border-color: $disabled-color;
        opacity: 1;
    }
    &:hover,
    &:focus,
    &:active,
    &:active:focus {
        color: inherit !important;
        border-color: inherit !important;
        background-color: inherit !important;
    }
}

// Button Circle
// -----------------------------------

.btn-circle {
    $sz: 35px;
    width: $sz;
    height: $sz;
    text-align: center;
    font-size: 12px;
    line-height: $sz;
    border-radius: 500px;
    padding: 0;
    border: 0;
    justify-content: center;
    > * {
        line-height: inherit !important;
    }

    &.btn-lg {
        $sz-lg: 50px;
        width: $sz-lg;
        height: $sz-lg;
        font-size: 18px;
        line-height: $sz-lg;
    }
    &.btn-sm {
        width: 25px;
        height: 25px;
        font-size: 9px;
        line-height: 25px;
        border-radius: 500px;
        padding: 2px;
        border: 0;
    }
}
.btn:not(.btn-full-width) {
    i.ph-light,
    i.ph-thin {
        vertical-align: -4px;
    }
}

//primeng by default uses "--apple-system" font
.p-button {
    font-family: $font-family-base;
}

.btn-full-width,
.btn-w-100-mob {
    text-align: left;
    margin-bottom: 0.5rem;
    display: flex !important;
    justify-content: space-between !important;
}
.btn-full-width {
    width: 100%;
}

.btn-left-content {
    justify-content: flex-start !important;
}

@media screen and (min-device-width: $screen-sm-max) {
    .btn-w-100-mob {
        width: initial;
    }
}

@media screen and (max-width: $screen-md-min) {
    .btn-w-100-mob {
        width: 100%;
    }
}
