// Override bootstrap variables
@import 'themes/theme-fa.scss';
@import 'themes/css-variables.scss';
// Bootstrap
@import '../../../node_modules/bootstrap/scss/_functions';
@import '../../../node_modules/bootstrap/scss/_variables';
@import '../../../node_modules/bootstrap/scss/_maps';
@import '../../../node_modules/bootstrap/scss/_mixins';

@import '../../../node_modules/primeng/resources/themes/bootstrap4-light-blue/theme.css';
@import '../../../node_modules/primeng/resources/primeng.css';

// Global definition of media queries
@import 'app/media-queries';
// Utilities
@import 'app/utils-definitions';

// Bootstrap reset
@import 'app/bootstrap-reset';

// Typography
@import 'app/typo';

// Layout
@import 'app/layout';
@import 'app/layout-extra';
@import 'app/layout-animation';
@import 'app/top-navbar';
@import 'app/sidebar';
@import 'app/offsidebar';

// Components
@import 'app/breadcrumbs';
@import 'app/loading-bar';
@import 'app/animate';
@import 'app/button-extra';
@import 'app/placeholder';
@import 'app/panels';
@import 'app/progress-extra';
@import 'app/nav-tabs';
@import 'app/steps';
@import 'app/user-block';
@import 'app/circles';
@import 'app/dialog';
@import 'app/dropdown-extra';
@import 'app/row-extra';
@import 'app/half-float';
@import 'app/widget';
@import 'app/table-grid';
@import 'app/todo';
@import 'app/form-elements';

// Tables
@import 'app/table-extras';

// Plugins
@import 'app/slim-scroll';
@import 'app/icon';
@import 'app/pagination';

// Utilities
@import 'app/utils';

// tag CSS
@import 'app/tag';

// Print CSS
@import 'app/print';

// Settings
@import 'app/settings';
