/* ========================================================================
     Component: sidebar
 ========================================================================== */

.sidebar {
    height: 100%;
    padding-bottom: 20px;
    background-color: $sidebar-bg;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;

    // draw a divider for the sidebar
    &:after {
        content: '';
        background: rgba(0, 0, 0, 0.15);
        position: absolute;
        display: block;
        top: 0;
        bottom: 0;
        right: 0;
        width: 1px;
        transform: translateZ(0px);
        -webkit-transform: translateZ(0px);
    }

    .nav-heading {
        padding: 12px 15px;
        color: $sidebar-heading-color;
        font-size: 13px;
        letter-spacing: 0.035em;
        user-select: none;
        cursor: default;
    }

    // Items
    // First main level
    > .nav {
        position: relative;

        // Only the first item in sidebar
        > .nav-heading:first-child {
            padding-top: 20px;
        }

        > li {
            @include transition(border-left-color 0.4s ease);

            > a,
            > .nav-item {
                padding: 12px 24px 12px 40px;
                color: $sidebar-item-color;
                letter-spacing: 0.025em;
                font-weight: normal;
                cursor: pointer;
                position: relative;
                .menu-icon {
                    position: absolute;
                    left: 15px;
                    top: 14px;
                }
                .fa-chevron-right,
                .fa-chevron-down {
                    position: absolute;
                    right: 12px;
                    top: 50%;
                    transform: translateY(-50%);
                }
                &:focus,
                &:hover {
                    text-decoration: none;
                    outline: none;
                    color: $brand-primary;
                }
                // Item icon
                > em {
                    width: 1.8em;
                    display: inline-block;
                    font-style: normal;
                    font-weight: normal;
                    line-height: 1;
                    -webkit-font-smoothing: antialiased;
                    color: $sidebar-icon-color;
                }
            }

            // Active item state
            &.active,
            &.open {
                &,
                > a,
                > .nav-item,
                .nav {
                    background-color: $sidebar-item-bg-active;
                    color: $sidebar-item-color-active;
                }
                > .nav-item > em,
                > a > em {
                    color: $sidebar-icon-color-active;
                }
            }

            &.active {
                border-left-color: $sidebar-item-color-active;
            }
        }
        .label {
            display: block;
        }
    }
}

// Hides the scrollbar only on desktops
@media only screen and (min-width: $tablet-landscape) {
    .sidebar:not(.show-scrollbar) {
        margin-right: -$scrollbar-wd;
        overflow-y: scroll;
    }
}

// Sub Levels
.sidebar-subnav {
    background-color: $sidebar-bg;

    > .sidebar-subnav-header {
        color: $sidebar-item-color;
        display: none;
        padding: 10px 20px;
        font-weight: bold;
    }

    > li {
        border-left: 0 !important;
        > a,
        > .nav-item {
            display: block;
            position: relative;
            padding: 10px 20px;
            padding-left: 53px;
            font-weight: normal;
            background-color: transparent !important;
            color: $sidebar-item-color;
            &:focus,
            &:hover {
                color: $brand-primary;
            }
            // submenu icons
            > em {
                display: inline-block;
                width: 2em;
                margin: 0 0 0 -2em;
            }
        }

        &.active {
            > a,
            > .nav-item {
                color: $brand-primary;
                &:after {
                    border-color: $sidebar-bullet-color-active;
                    background-color: $sidebar-bullet-color-active;
                }
            }
        }

        // Nested levels
        .nav > li {
            padding-left: 5px;
        }
    } // li

    // This only happend when sidebar is collapsed and the submenu
    // visibility is toggled via js
    &.nav-floating {
        border: 1px solid rgba(0, 0, 0, 0.15);
        margin-left: -1px;
        // we resuse the collapse element so we need to override
        &,
        .sidebar-subnav {
            height: auto !important;
            display: block !important;
            visibility: visible !important;
            animation: none !important;
        }
        > .sidebar-subnav-header {
            display: block;
        }
        // reduce padding when item is on sidebar collapsed
        li > a,
        li > .nav-item {
            padding-left: 20px;
            // submenu icons
            em {
                margin-left: 0;
            }
        }
    }
}

// Desktop styles
// ---------------------------------
@media only screen and (min-width: $mq-tablet) {
    .sidebar > .nav {
        .label {
            margin: 2px 0 0 0;
        }
    }
} // @media @desktop

// Collapsed state styles
.aside-collapsed,
.aside-collapsed-text {
    .sidebar {
        overflow-x: hidden;
        // Applies to first level items
        > .nav {
            > li.nav-item {
                width: $aside-wd-collapsed - 1;
                text-align: center;
                padding: 0;
                .menu-icon {
                    position: static;
                    font-size: 1.4em;
                }
                > * {
                    padding: 14px 0;
                    text-align: center;
                    text-decoration: none;
                    > *:not(.menu-icon):not(ul):not(li) {
                        display: none;
                    }
                    > .menu-icon {
                        font-size: 1.6em; // increase icons size
                        width: auto !important;
                    }
                    &:focus {
                        // accessibility
                        > .menu-icon {
                            color: inherit;
                        }
                    }
                }

                > .nav.sidebar-subnav {
                    &.opening {
                        display: block;
                        @media only screen and (max-width: $mq-tablet) {
                            display: none;
                        }
                    }
                    position: absolute;
                    display: none;
                    left: 70px;
                    top: 0;
                    text-align: left;
                    border: 1px solid #eee;
                    border-left: none;
                    margin-top: -1px;

                    > li {
                        white-space: nowrap;
                        a {
                            padding-left: 20px;
                        }
                    }
                }
            }
        }

        // labels in menu items
        .nav {
            .label {
                position: absolute;
                top: 10px;
                right: 5px;
                text-indent: 0;
            }
        }

        &.light {
            .nav-item {
                > a {
                    padding-right: 0 !important;
                    padding-left: 0 !important;
                }
            }
        }
    } // .sidebar
} // .aside-collapsed/text

.aside-collapsed-text {
    .sidebar {
        > .nav {
            > li > .nav-item > span,
            > li > a > span {
                display: block !important;
                font-size: 12px;
            }

            > li {
                width: $aside-wd-collapsed-text - 2;
                > a {
                    padding: 14px 0;
                    > em {
                        font-size: 1.4em;
                    }
                }
            }
        }
    }
}

.sidebar-subnav {
    height: 0;
    overflow: hidden;
    transition: height 0.2s ease;
    &.opening {
        height: auto;
        transition: height 0.2s ease;
        animation: fadeIn 1s ease-in-out forwards; /* Optional: Specify animation properties */
        -webkit-animation: fadeIn 1s ease-in-out forwards; /* Safari support */
    }
}
