/* ========================================================================
     Component: widget
 ========================================================================== */

//
// Extras styles to use panel as widgets
// -------------------------------------

.widget {
    margin-bottom: $widget-margin;
    border: 0;

    &.panel,
    & .panel {
        // overflow: hidden;
    }

    .lateral-picture {
        position: relative;
        display: block;
        height: 240px;
        width: auto;
        overflow: hidden;
        > img {
            position: absolute;
            top: 0;
            left: 0;
            max-height: 100%;
            width: auto;
        }
    }

    &-title {
        background-color: $brand-primary;
        color: #fff;
        font-weight: bold;
        padding: 15px 20px;
        margin-top: -15px;
        &.type-2 {
            background-color: transparent;
            color: $brand-primary;
            h2 {
                font-size: 20px;
                padding-bottom: 15px;
                border-bottom: 1px solid $brand-primary;
            }
        }
        h2 {
            font-size: 16px;
            margin: 0;
            color: #fff;
        }
    }
}

.question-title {
    margin: 0 0 20px 0;
    font-size: 20px;
    clear: both;
    padding-bottom: 10px;
    color: $brand-primary;
    border-bottom: 1px solid $brand-primary;
}

.question-row {
    margin-bottom: 20px;
}
