.nav-tabs {
    background-color: #fff;

    li.nav-item {
        a.nav-link {
            font-weight: 400;

            &.active,
            &:hover,
            &:focus {
                border-bottom-width: 4px;
                border-bottom-color: $brand-primary;
                color: $brand-text;
                font-weight: 800;
            }
        }
    }
}
