:root {
    --screen-md-min: #{$screen-md-min};
    --screen-lg-min: #{$screen-lg-min};
    --brand-primary: #{$brand-primary};
    --brand-primary-dark: #{$brand-primary-dark};
    --brand-secondary-light: #{$brand-secondary-light};
    --brand-secondary-dark: #{$brand-secondary-dark};
    --gray: #{$gray};
    --gray-darker: #{$gray-darker};
    --gray-light: #{$gray-light};
    --gray-lighter: #{$gray-lighter};
    --brand-info: #{$brand-info};
    --brand-warning: #{$brand-warning};
    --brand-danger: #{$brand-danger};
    --text-color: #{$text-color};
    --link-color: #{$link-color};
    --brand-text: #{$brand-text};
    --brand-badge-secondary: #{$brand-badge-secondary};
    --bs-body-font-size: 12px;
}
