/* ========================================================================
   Component: layout-animation.less
 ========================================================================== */

.wrapper > .aside {
  @include transition(#{"width .2s cubic-bezier(0.35, 0, 0.25, 1), translate .2s cubic-bezier(0.35, 0, 0.25, 1)"});
}
.aside-inner,
.navbar-header,
.sidebar > .nav > li {
  @include transition(width .2s cubic-bezier(0.35, 0, 0.25, 1));
}
.wrapper > section {
  @include transition(margin-left .2s cubic-bezier(0.35, 0, 0.25, 1));
}
.sidebar > .nav .label {
  animation: fadeInRight 1s ease-in-out forwards; /* Optional: Specify animation properties */
  -webkit-animation: fadeInRight 1s ease-in-out forwards; /* Safari support */
}

.sidebar > .nav .label.animate {  /* Add animation class to parent element */
  animation-delay: 0s; /* Optional: Start animation immediately */
  -webkit-animation-delay: 0s;
}
.aside-collapsed .sidebar > .nav .label,
.aside-collapsed-text .sidebar > .nav .label {
  animation: fadeIn 1s ease-in-out forwards; /* Optional: Specify animation properties */
  -webkit-animation: fadeIn 1s ease-in-out forwards; /* Safari support */
}

.aside-collapsed .sidebar > .nav .label.animate,  /* Add animation class to parent element */
.aside-collapsed-text .sidebar > .nav .label.animate {
  animation-delay: 0s; /* Optional: Start animation immediately */
  -webkit-animation-delay: 0s;
}

.sidebar > .nav > .nav-heading,
.sidebar > .nav > li > a > span,
.navbar-brand .brand-logo {
  animation: fadeIn 1s ease-in-out forwards; /* Optional: Specify animation properties */
  -webkit-animation: fadeIn 1s ease-in-out forwards; /* Safari support */
}

.aside-collapsed,
.aside-collapsed-text {
  .user-block-picture {
    @include transition(width .2s cubic-bezier(0.35, 0, 0.25, 1));
  }
  .user-block {
    @include transition(padding .2s cubic-bezier(0.35, 0, 0.25, 1));
  }
}
