/* ========================================================================
     Component: Primeng dialog
 ========================================================================== */
.p-dialog {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    border-radius: 16px;
    font-size: $primeng-font-size;
    max-width: 100% !important;
}
.p-dialog .p-dialog-header {
    border-bottom: none;
    background: #ffffff;
    color: $brand-text;
    padding: 1rem;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
}
.p-dialog .p-dialog-header .p-dialog-title {
    font-weight: 600;
    font-size: 1rem;
}
.p-dialog .p-dialog-footer {
    border-top: 1px solid #f9f9f9;
    background: #ffffff;
    color: #ffffff;
    padding: 1.5rem;
    text-align: right;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
}
.p-custom-dialog-footer {
    display: block !important;
    position: absolute !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    padding: 12px !important;
}

.p-dialog .p-dialog-footer button,
.p-dialog .p-dialog-footer a {
    margin: 0 0.5rem 0 0;
    width: auto;
    border-radius: 10px;
}
p-confirmdialog {
    .p-dialog .p-dialog-footer {
        padding: 1rem;
    }
}

//Confirmation dialog
.p-custom-confirm-dialog {
    .p-dialog-header {
        padding: 0.7rem;
        padding-bottom: 2px !important;
    }
    .p-dialog-content {
        padding: 35px;
        padding-top: 15px;
        border-bottom-right-radius: 16px !important;
        border-bottom-left-radius: 16px !important;

        .confirmation-container {
            display: flex;
            flex-direction: column;
            gap: 25px;

            .text-container {
                display: flex;
                flex-direction: column;
                gap: 4px;

                .header-text {
                    text-align: center;
                    font-weight: 600;
                    font-size: 1.3rem;
                }
                .content-text {
                    text-align: center;
                    font-size: 0.8rem;
                    color: $brand-text;
                }
            }
        }
        .btn {
            border-radius: 10px;
        }
    }
}

.p-dialog .p-dialog-footer button.btn-dark,
.p-dialog .p-dialog-footer a.btn-dark,
.p-dialog .p-dialog-footer button.btn-grey-dark,
.p-dialog .p-dialog-footer a.btn-grey-dark {
    &:hover,
    &:focus,
    &:active,
    &:active:focus {
        color: $gray-darkest !important;
        border-color: $gray-darkest !important;
    }
}
.p-dialog .p-dialog-content {
    .p-dialog-content {
        padding-top: 0;
    }
}

@media (min-width: $screen-sm-min) {
    .p-dialog-content-overflow.p-dialog-content {
        overflow-y: visible;
        padding-top: 0;
    }
}

.p-dialog-content-overflow-all-device.p-dialog-content {
    overflow-y: visible;
    padding-top: 0;
}

//Session selector dialog
.p-session-dialog {
    .p-dialog-header {
        padding-bottom: 3px !important;
    }

    .p-dialog-content {
        padding: 2em;
        padding-top: 1px;
        border-bottom-right-radius: 16px !important;
        border-bottom-left-radius: 16px !important;

        .p-calendar {
            width: 100%;

            .p-datepicker {
                overflow: visible;
            }

            .p-datepicker table th {
                text-align: center;
            }

            .p-datepicker .p-yearpicker .p-yearpicker-year.p-highlight {
                color: initial;
            }
        }
    }
}
