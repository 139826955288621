/* ========================================================================
     Component: todo
 ========================================================================== */
.todo {
    .todo-item-list {
        position: relative;
    }
    .todo-item {
        @include transition("color 0.6s, background-color 0.3s");

        &.ng-enter {
            //@include animation(fadeInLeft 1s);
        }
        &.ng-leave {
            position: absolute;
            left: 0;
            right: 0;
          //  @include animation(fadeOutDown .7s);
           // @include animation-fill-mode(both);
        }
        .todo-edit {
            display: none;
            cursor: pointer;
        }
        &:hover .todo-edit {
            display: inline;
        }
        &.todo-complete .todo-edit {
            display: none;
        }
    }
    textarea {
        resize: none;
        max-width: 100%;
        min-width: 100%;
    }
    .todo-complete {
        // background: $gray-lighter;
        opacity: .6;
        color: $gray-darker;

        .todo-title {
            text-decoration: line-through;
        }
    }
    .panel-group {
        > .panel {
            border: 1px;
            margin: 0;

            .panel-body {
                border-bottom: 1px solid $gray-lighter;
            }
        }
    }
}
